.admin {
  display: flex;
  padding-top: 6rem;
}
.admin .navbar {
  width: 25%;
  min-height: 80vh;
}
.admin .content {
  width: 75%;
  padding: 1rem;
}