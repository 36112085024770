.product {
  display: flex;
  position: relative;
}
.product .filter {
  width: 200px;
  transition: all 0.3s;
}
.product .content {
  width: 100%;
  padding-left: 5px;
  position: relative;
}
.product .content .icon {
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}
.product .content .icon > * {
  padding-left: 5px;
}
/*Spinny*/

/*Spinny*/
@media screen and (max-width: 700px) {
  .product .filter {
    width: 50%;
    background-color: #fff;
    border: 2px solid var(--dark-blue);
    position: absolute;
    left: -200%;
    height: 100%;
    padding: 1rem;
    z-index: 99;
  }
  .product .show {
    left: 0;
  }
  .product .content {
    width: 100%;
  }
  .product .content .icon {
    display: flex;
  }
}