header {
  width: 100%;
  position: fixed;
  top: 0;
  transition: all 0.5s;
  z-index: 9;
  background-color: var(--dark-blue);
  color: #fff;
}
header .header {
  width: 100%;
  height: 6rem;
  /* max-width: 1000px; */
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
header .logo{
  display: flex;
  justify-content: space-between;
}

.piblock{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.pilo{
  margin:0 10px 0 10px;
}
.xmas{
  height: 30px;
}

header .logo a h2 {
  width: 25%;
  color: #fff;
  cursor: pointer;
}
header .logo a h2 span {
  color: #00FF00;
}
header nav {
  width: 75%;
  display: flex;
  justify-content: space-between;
}
header nav ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
}
header nav ul .logo-mobile {
  display: none;
}
header nav ul li {
  margin: 0 5px;
}
header nav ul li a {
  color: #fff;
}
header nav ul li a:hover {
  color:#ff9700;
}
header .header-right {
  display: flex;
}
header .header-right .cart a {
  display: flex;
  color: #fff;
  position: relative;
}
header .header-right .cart a:hover {
  color:#ff9700;
}
header .header-right .cart a.active {
  color: var(--color-danger);
}
header .header-right .cart a p {
  position: absolute;
  top: -1rem;
  right: -1rem;
  font-weight: 500;
}
header .header-right span {
  margin: 0 5px;
}
header .header-right span p {
  color: #fff;
}
header .header-right .links a {
  margin: 0 5px;
  color: #fff;
}
header .header-right .links a:hover {
  color:#ff9700;
}
header .header-right .links a.active {
  color: var(--color-danger);
}
header .menu-icon {
  cursor: pointer;
  display: none;
}
@media screen and (max-width: 800px) {
  header nav {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    background-color: var(--dark-blue);
    padding: 1rem;
    z-index: 999;
    transform: translateX(-200%);
    transition: all 0.3s;
  }
  header nav .nav-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translateX();
    transition: all 0.3s;
  }
  header nav .show-nav-wrapper {
    transform: translateX(100%);
  }
  header nav ul {
    display: block;
  }
  header nav ul .logo-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  header nav ul .logo-mobile > * {
    cursor: pointer;
  }
  header nav ul li {
    padding: 5px 0;
    border-bottom: 1px solid #333;
  }
  header nav ul li a {
    display: block;
  }
  header nav .header-right {
    display: block;
  }
  header nav .header-right .cart {
    border-bottom: 1px solid #333;
  }
  header nav .header-right .links {
    display: block;
  }
  header nav .header-right .links a {
    display: block;
    margin: 0;
    padding: 5px 0;
    border-bottom: 1px solid #333;
  }
  header .cart {
    display: block;
    padding: 0 10px 0 0;
  }
  header .cart a {
    color: #fff;
    position: relative;
  }
  header .cart a:hover {
    color: orangered;
  }
  header .cart a p {
    position: absolute;
    top: -1rem;
    left: 5rem;
    font-weight: 500;
    color: #fff;
  }
  header .show-nav {
    transform: translateX(0);
  }
  header .hide-nav {
    transform: translateX(-200%);
  }
  header .menu-icon {
    display: flex;
  }
  header .menu-icon > * {
    margin-left: 2rem;
  }
}

.active {
  position: relative;
  color: var(--color-danger);
}
.active::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #fff;
}