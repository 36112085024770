.footer {
  background-color: var(--dark-blue);
  color: #fff;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5rem;
}
.footer p {
  color: #fff;
}
.piblock{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
}
.pilo{
  margin: 5px 10px;
}