@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --font-family: "Poppins", sans-serif;
  --dark-blue: #0a1930;
  --light-blue: #1f93ff;

  --color-white: #fff;
  --color-dark: #333;

  --color-grey: #eee;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);

  --color-purple: #9d0191;
  --color-orange: #ff7722;

  --color-primary: #007bff;
  --color-success: #28a744;
  --color-danger: #ff9700;

  --mainColor5:#FA6052;
  --mainColor6:#00EB00;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: var(--font-family);
}

section {
  width: 100%;
  padding: 4rem 0;
}

.container {
  margin: 0 auto;
  padding: 20px 20px 0 20px;
}

/* UTILITY CLASSES */

/* Flex */
.--flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.--flex-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.--flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.--flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.--dir-column {
  flex-direction: column;
}

.--flex-dir-column {
  display: flex;
}

.--align-center {
  display: flex;
  align-items: center;
}

.--100vh {
  height: 100vh;
}

.--mh-100vh {
  min-height: 100vh;
}

/* Grid */
.--grid-15 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}
.--grid-25 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  row-gap: 1rem;
  column-gap: 1rem;
}

/* Center All */
.--center-all {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: auto;
  text-align: center;
}

/* Heading */
h1,
h2,
h3,
h4 {
  font-weight: 500;
  line-height: 1.2;
  color: var(--color-dark);
  margin-bottom: 1rem;
}
h1 {
  font-size: 4rem;
}
h2 {
  font-size: 3rem;
}
h3 {
  font-size: 2.5rem;
}
h4 {
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}
.--text-xl {
  font-size: 4.5rem;
}
.--text-lg {
  font-size: 4rem;
}

.--text-md {
  font-size: 3rem;
}

.--text-sm {
  font-size: 1.2rem;
  font-weight: 300;
}

.--text-p {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.3;
  color: var(--color-dark);
}

.--fw-bold {
  font-weight: 600;
}
.--fw-thin {
  font-weight: 200;
}

/* Text Color */
.--text-light {
  color: #fff;
}

.--color-primary {
  color: #007bff;
}
.--color-danger {
  color: orangered;
}
.--color-success {
  color: #28a745;
}

.--color-white {
  color: #fff;
}

/* Center Text */
.--text-center {
  text-align: center;
}

/* Card */
.--card {
  border: 1px solid transparent;
  border-radius: 5px;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}

/* Margin */
.--m {
  margin: 1rem;
}
.--ml {
  margin-left: 1rem;
}
.--mr {
  margin-right: 1rem;
}

.--mb {
  margin-bottom: 1rem;
}

.--my {
  margin: 1rem 0;
}
.--mx {
  margin: 0 1rem;
}

.--m2 {
  margin: 2rem;
}

.--ml2 {
  margin-left: 2rem;
}
.--mr2 {
  margin-right: 2rem;
}

.--mb2 {
  margin-bottom: 2rem;
}

.--my2 {
  margin: 2rem 0;
}

.--mx2 {
  margin: 0 2rem;
}

/* Padding */
.--p {
  padding: 1rem;
}
.--p2 {
  padding: 2rem;
}
.--py {
  padding: 1rem 0;
}
.--py2 {
  padding: 2rem 0;
}
.--px {
  padding: 0 1rem;
}
.--px2 {
  padding: 0 2rem;
}

.--btn {
  font-size: 1.6rem;
  font-weight: 400;
  padding: 6px 8px;
  margin: 0 5px 0 0;
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}

.--pibtn{
  background: #e200f7c5;
}

.--btn:hover {
  transform: translateY(-2px);
}

.--btn-lg {
  padding: 8px 10px;
}

.--btn-block {
  width: 100%;
}

.--btn-primary {
  color: #fff;
  background: #007bff;
}
.--btn-secondary {
  color: #fff;
  border: 1px solid #fff;
  background: transparent;
}
.--btn-danger {
  color: #fff;
  background: orangered;
}

.--btn-success {
  color: #fff;
  background: #28a745;
}
.--btn-2{
    color: rgb(255, 255, 255);
    transition: all 0.2s cubic-bezier(0.72, 0.01, 0.56, 1) 0s;
	-webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 6px 8px;
    margin: 0 5px 0 0;
    border: 1px solid transparent;
    border-radius: 3px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
}
#google-connect{
  background: rgb(255, 255, 255) url('./pages/auth/google.svg?sanitize=true') no-repeat scroll 15px 8px / 18px 18px border-box;
  background-position: 20px;
  border: 1px solid rgb(220, 74, 61);
  
}
#google-connect:hover {
	border-color: rgb(220, 74, 61);
	background: rgb(220, 74, 61) url('./pages/auth/googleWhite.svg?sanitize=true') no-repeat scroll 15px 8px / 18px 18px border-box;
	background-position: 20px;
  -webkit-transition: all .8s ease-out;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease-out;
}

#google-connect span {
	box-sizing: border-box;
  color: rgb(220, 74, 61);
  cursor: pointer;
  text-align: center;
  border: 0px none rgb(220, 74, 61);
  outline: rgb(255, 255, 255) none 0px;
	-webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}

#google-connect:hover span {
	color: #FFF;
	-webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
}

/* Background */
.--bg-light {
  background: #fff;
}
.--bg-dark {
  background: var(--color-dark);
}
.--bg-primary {
  background: var(--color-primary);
}
.--bg-success {
  background: var(--color-success);
}
.--bg-grey {
  background: var(--color-grey);
}
.--form-control {
  font-size: 1.6rem;
  font-weight: 300;
}

.--form-control > * {
  margin: 5px 0;
}

.--form-control input {
  font-size: 1.6rem;
  font-weight: 300;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}
.--form-control select {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 8px 1rem;
  border: 1px solid #777;
  border-radius: 3px;
}

.--form-control label {
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  min-width: 7rem;
  color: var(--color-dark);
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .--flex-dir-column {
    flex-direction: column;
  }
}

.--block {
  display: block;
}
.--inline-block {
  display: inline-block;
}

.--width-100 {
  width: 100%;
}

.--width-500px {
  width: 500px;
}

.--line {
  position: relative;
}
.--line::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -5px;
  transform: translateX(-50%);
  width: 5rem;
  height: 3px;
  margin-bottom: 1rem;
  background: rgb(217, 8, 170);
  background: linear-gradient(
    135deg,
    rgba(163, 1, 191, 1) 44%,
    rgba(217, 8, 170, 1) 57%
  );
}

.--list-style-none {
  list-style: none;
}

.--profile-img {
  width: 6rem;
  height: 6rem;
  border: 1px solid #ccc;
  border-radius: 50%;
}

a {
  font-size: 1.4rem;
  color: var(--dark-blue);
  text-decoration: none;
  transition: all 0.2s;
}

a:hover {
  color: var(--color-dark);
}
/* page not find */
.left-section .inner-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.inner-content img{
  width: 75px;
  display: block;
  margin: 0 auto;
}

.heading {
  text-align: center;
  font-size: 5em;
  line-height: 1.3em;
  margin: 0 0 0.5rem 0;
  padding: 0;
  text-shadow: 0 0 1rem #fefefe;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #080710;
}
.background .shape{
  height: 200px;
  width: 200px;
  position: absolute;
}
/* .shape:first-child{
  background: url('./pages/auth/Stamp-ETRALIS.png') no-repeat;
  left: 50%;
  top: 80px;
} */
.notFound {
  font-family: "Fira Sans", sans-serif;
  color:#f5f6fa;
  position: relative;
  margin: 0 auto;
  width: 85%;
  height: 100vh;
  padding-bottom: 25vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.submit {
  display: block;
  margin: 1em auto 0 auto;
  background: none;
  border: 2px solid;
  font: inherit;
  line-height: 1;
  padding: 0.5em 1em;
  cursor: pointer;
  transition: 0.25s;
  border-radius: 5px;
  color: #15be05;
}
.submit:hover{
    border-color: var(--hover);
    color: #60fc52;
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.25em);
}

.left-section, .right-section {
  position: relative;
}

.left-section {
  width: 40%;
}

.subheading {
  text-align: center;
  font-size: 1em;
  line-height: 1.15em;
  padding: 0 1rem;
}

.right-section {
  width: 50%;
}

.svgimg {
  position: absolute;
  bottom: 0;
  padding-top: 10vh;
  padding-left: 1vh;
  max-width: 100%;
  max-height: 100%;
}
.svgimg .bench-legs {
  fill: #0C0E10;
}
.svgimg .top-bench, .svgimg .bottom-bench {
  stroke: #0C0E10;
  stroke-width: 1px;
  fill: #5B3E2B;
}
.svgimg .bottom-bench path:nth-child(1) {
  fill: #432d20;
}
.svgimg .lamp-details {
  fill: #202425;
}
.svgimg .lamp-accent {
  fill: #2c3133;
}
.svgimg .lamp-bottom {
  fill: linear-gradient(#202425, #0C0E10);
}
.svgimg .lamp-light {
  fill: #EFEFEF;
}

/* load */
.--load {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  margin: 20vh auto;
}
.--load hr {
  border: 0;
  margin: 0;
  width: 40%;
  height: 40%;
  position: absolute;
  border-radius: 50%;
  animation: spin 2s ease infinite
}
.--load :first-child {
  background: #19A68C;
  animation-delay: -1.5s
}
.--load :nth-child(2) {
  background: var(--mainColor5);
  animation-delay: -1s
}
.--load :nth-child(3) {
  background: #FDA543;
  animation-delay: -0.5s
}
.--load :last-child {
  background: var(--mainColor6)
}
@keyframes spin {
  0%,100% {
      transform: translate(0)
  }
  25% {
      transform: translate(160%)
  }
  50% {
      transform: translate(160%, 160%)
  }
  75% {
      transform: translate(0, 160%)
  }
}

/* load */

@keyframes glow {
  0% {
    text-shadow: 0 0 1rem #fefefe;
  }
  50% {
    text-shadow: 0 0 1.85rem #ededed;
  }
  100% {
    text-shadow: 0 0 1rem #fefefe;
  }
}
@media (max-width: 770px) {
  .background .ground {
    height: 0vh;
  }
  .notFound {
    flex-direction: column;
    padding-bottom: 0vh;
  }
  .left-section {
    width: 100%;
    height: 40%;
    position: absolute;
    top: 0;
  }
  .left-section .inner-content {
    position: relative;
    padding: 1rem 0;
  }
  .heading {
    font-size: 4em;
    line-height: 1.15;
    margin: 0;
  }
  .subheading {
    font-size: 1em;
    line-height: 1.15;
    max-width: 100%;
  }
  .right-section {
    width: 100%;
    height: 60%;
    position: absolute;
    bottom: 0;
  }
  .svgimg {
    padding: 0;
  }
}