.contact .card {
  padding: 1rem;
  border: 1px solid #ccc;
}
.contact .card2 {
  padding: 2rem;
  background-color: #222;
  color: #fff;
}
.contact .card2 h3, .contact .card2 p {
  color: #fff;
}
.contact .card2 .icons {
  margin: 3rem 0;
}
.contact .card2 .icons span {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-bottom: 1rem;
}
.contact .card2 .icons span a, .contact .card2 .icons span p {
  margin-left: 5px;
}
.contact .section {
  display: flex;
  align-items: start;
}
.contact .section form {
  width: 500px;
  max-width: 100%;
  margin-right: 1rem;
  margin-bottom: 1rem;
}
.contact .section form label {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
}
.contact .section form input[type="text"], .contact .section form input[type="number"], .contact .section form input[type="file"], .contact .section form input[type="email"], .contact .section form select, .contact .section form textarea, .contact .section form input[type="password"] {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  margin: 1rem auto;
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}
@media screen and (max-width: 700px) {
  .contact .section {
    flex-direction: column;
  }
}