.checkout {
  width: 100%;
  position: relative;
}
.checkout .card {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
}
.checkout .card h3 {
  font-weight: 300;
}
.checkout form {
  width: 100%;
  display: flex;
}
.checkout form div {
  width: 100%;
}
.checkout form label {
  display: block;
  font-size: 1.4rem;
  font-weight: 500;
}
.checkout form input[type="text"], .checkout form .select, .checkout form .card-details {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  margin: 1rem auto;
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}
@media screen and (max-width: 700px) {
  .checkout form {
    flex-direction: column;
  }
  .checkout form div {
    width: 100%;
  }
}
#payment-message {
  color: #697386;
  color: red;
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}
#payment-element {
  margin-bottom: 24px;
}
/* Buttons and links */
.button {
  background: #5469d4;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 4px;
  border: 0;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  width: 100%;
  position: relative;
}
.button:hover {
  filter: contrast(115%);
}
.button:disabled {
  opacity: 0.5;
  cursor: default;
}