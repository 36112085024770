.auth {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth .img {
  animation: slide-down 0.5s ease;
}
.auth .form {
  position: absolute;
  transform: translate(-50%,-50%);
  width: 35rem;
  background-color: rgba(255,255,255,0.13);
  top: 50%;
  left: 50%;
  border-radius: 10px;
  backdrop-filter: blur(10px);
  border: 2px solid rgba(255,255,255,0.1);
  box-shadow: 0 0 40px rgba(8,7,16,0.6);
  padding: 50px 35px;
}
.auth .form h2 {
  color: #FFFFFF;
  text-align: center;
}
.auth .form form input[type="text"], .auth .form form input[type="email"], .auth .form form input[type="password"] {
  display: block;
  font-size: 1.6rem;
  font-weight: 300;
  padding: 1rem;
  margin: 1rem auto;
  width: 100%;
  border: 1px solid #777;
  border-radius: 3px;
  outline: none;
}
.auth .form form .links {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  /* color: #FFFFFF; */
}
.auth .form form a,
.auth .form .register a{
  color: #FFFFFF;
}

.auth .form form p {
  text-align: center;
  margin: 1rem;
  /* color: #FFFFFF; */
}
.auth .form .register {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  color: #FFFFFF;
}
.auth .form p{
  color: #cecece;
}
@keyframes slide-up {
  0% {
    transform: translateY(-5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(5rem);
  }
  100% {
    transform: translateY(0);
  }
}
@media screen and (max-width: 800px) {
  .img {
    display: none;
  }
}