.grid {
  width: 14rem;
  background-color: #fff;
  margin: 5px;
  height: 28rem;
  position: relative;
}
.grid .img {
  padding: 1rem;
  width: 100%;
  max-height: 75%;
  overflow: hidden;
  border-bottom: 2px solid #eee;
}
.grid .img img {
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}
.grid .content {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
.grid .content .details {
  padding: 0 1rem;
}
.grid .content .details h4 {
  font-weight: 400;
  font-size: 1.8rem;
}
.grid .content .details p {
  font-weight: 500;
  color: orangered;
}
.grid .content button {
  display: block;
  width: 100%;
}
.list {
  width: 100%;
  height: 28rem;
  max-height: 32rem;
  display: flex;
  background-color: #fff;
  margin: 1rem 0;
}
.list .img {
  padding: 1rem;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-right: 2px solid #eee;
}
.list .img img {
  width: 100%;
  max-height: 100%;
  cursor: pointer;
}
.list .content {
  position: relative;
  padding: 1rem;
  width: 65%;
}
.list .content .details {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.list .content .details h4 {
  font-weight: 400;
}
.list .content .details p {
  font-weight: 500;
  color: var(--color-danger);
}
.list .content button {
  position: absolute;
  bottom: 1rem;
  left: 1rem;
}